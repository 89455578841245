@import "../../../styles/lib";

.CustomArrow {
  display: flex !important;
}

.HomepageSlide {
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  min-height: 770px;

  @include tablet {
    min-height: calc(100vh - 80px);
    img {
      min-height: calc(100vh - 80px);
    }
  }

  @include mobile {
    min-height: 300px;
    img {
      min-height: 300px;
    }
  }
  &__item {
    width: 100%;
    height: 770px;
    position: relative;
  }
  &__content {
    position: absolute;
    max-width: 500px;
    width: 100%;
    z-index: 3;
    left: 100px;
    bottom: 100px;
    @include mobile {
      left: 15px;
      bottom: 50px;
      max-width: calc(100% - 30px);
    }
    h2 {
      font-size: 28px;
      font-weight: 700;
      line-height: 1.35em;
      margin-bottom: 20px;
    }
    .btn {
      border: 3px solid $primary;
      padding: 15px 50px;
      font-size: 18px;
      color: $primary;
      font-weight: 700;
      position: relative;
      overflow: hidden;
      @include mobile {
        padding: 10px 30px;
      }
      &:before {
        content: "";
        width: 0;
        height: 100%;
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        background-color: $primary;
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        color: #fff;
        &:before {
          width: 100%;
        }
      }
    }
  }

  img {
    width: 100%;
    height: 770px;
    object-fit: cover;
    @include mobile {
      height: 300px;
    }
  }

  .slick-slide,
  .slick-track {
    :focus {
      outline: none !important;
    }
    outline: none !important;
  }

  .slick-arrow {
    top: 50% !important;
    transform: translateY(-50%) !important ;
    z-index: 1;
    width: 30px;
    height: 25px;
    &:before {
      content: "";
      display: none;
    }
    &:hover {
      fill: $primary;
    }
  }

  .slick-prev {
    left: 50px;
    @include mobile {
      left: 5px;
    }
  }
  .slick-next {
    transform: translateY(-50%) rotate(180deg) !important;
    right: 50px;

    @include mobile {
      right: 5px;
    }
  }

  @include tablet {
    margin-left: -30px;
    margin-right: -30px;
    width: auto;
    min-height: 322px;
    a {
      img {
        object-fit: cover;
      }
    }
  }

  @include mobile {
    margin-left: -15px;
    margin-right: -15px;
    width: auto;
    min-height: 222px;
    a {
      img {
        object-fit: cover;
      }
    }
  }

  &__btn-down {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
