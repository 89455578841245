@import "src/styles/lib";

.BlockButton {
  display: block;
  font-size: 12px;
  font-weight: 500;
  min-width: 112px;
  min-height: 47px;
  color: #000000;
  padding: 2px 40px;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.15em;
  transition: 0.25s ease;
  background: transparent;
  text-transform: uppercase;
  border: 1px solid #000000;
  line-height: em(40px, 12px);
  &:disabled {
    border: 1px solid #a2a2a2;
    color: #a2a2a2;
    &:hover {
      color: #a2a2a2 !important;
      background: transparent;
      cursor: not-allowed;
    }
  }
  &--inverse {
    border: 1px solid #ffffff;
    color: #ffffff;
    &:hover {
      background-color: #ffff !important;
      color: #000000 !important;
    }
  }
  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
  &--fill {
    background-color: black;
    color: #fff;
    &:hover {
      background-color: transparent !important;
      color: #000 !important;
    }
    &.BlockButton--inverse {
      background-color: #fff !important;
      border: 1px solid #ffffff;
      color: #000;
      &:hover {
        background-color: transparent !important;
      }
    }
  }
}
