@import "../../../styles/lib";

.BrandListItemPlaceholder {
  display: block;
  width: 100%;
  height: 110px;
  margin-bottom: 30px;
  @include mobile {
    margin-bottom: 15px;
    height: auto;
  }
  @include tablet {
    height: auto;
  }
}
