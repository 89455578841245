@import "../../../styles/lib";

.HomepageSlideLoader {
  width: 100%;
  height: 100%;

  &__full {
    width: 100%;
    height: calc(100% - 123px);
    display: block;
    @include tablet {
      height: 322px;
    }
    @include mobile {
      height: 222px;
    }
  }
}
