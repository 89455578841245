@import "src/styles/lib";

.Newsletter {
  background-color: $pink;
  padding: 70px 0;
  margin-top: 50px;
  color: #fff;
  @include mobile {
    padding: 30px 15px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mobile {
      flex-direction: column;
    }
  }

  &__text {
    font-weight: 700;
  }

  &__form {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    @include mobile {
      width: 100%;
      margin-top: 20px;
    }

    input {
      width: 100%;
      max-width: 500px;
      border-radius: 1px;
      min-height: 60px;
      background-color: #ffffff;
      border: 1px solid #fff;
    }

    button {
      padding-right: 30px;
      padding-left: 30px;
      border-radius: 0;
      min-width: 100px;
    }
  }
}
