@import "../../styles/lib";

.BrandItem {
  width: 100%;
  height: 110px;
  max-height: 110px;
  border: 1px $border-color solid;

  @include tablet {
    height: 90px;
    max-height: 90px;
  }

  a {
    width: 100%;
    height: 100%;
    display: block;
  }

  &__image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    @include tablet {
      padding: 0;
    }

    img {
      opacity: 1;
      display: block;
      max-width: 100%;
      max-height: 100%;
      transition: 300ms ease-in-out;
      @include mobile {
        max-width: unset;
        max-height: unset;
        width: 107px;
        opacity: 1;
      }
      @include tablet {
        max-width: unset;
        max-height: unset;
        width: 107px;
        opacity: 1;
      }
    }

    &:hover {
      img {
        opacity: 0.5;
      }
    }
  }
}
