@import "styles/lib";

.App {
  &__backdrop {
    width: 100%;
    height: 100%;
    background: rgba(33, 33, 33, 0.52);
    position: fixed;
    z-index: 10;
    display: none;
  }

  &--loading {
    .LoadingPlaceholder {
      svg {
        color: $primary;
      }
      p {
        font-size: 28px;
        color: $primary;
      }
    }
  }

  &--show-backdrop .App__backdrop {
    display: block;
  }
}
