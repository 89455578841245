@import "./../../../styles/_lib.scss";

.ButtonFilterMobile {
  display: none;

  @include mobile {
    width: 50%;
    display: block;

    button {
      display: flex !important;
      justify-content: flex-start;
      border: 1px solid #d9d9d9;
      position: relative;
      font-weight: 500;
      text-align: left;
      .Icon {
        width: 12px;
        height: 12px;

        position: absolute;
        right: 5px;
      }
    }

    &__nav {
      width: 100%;
      border: none;
      height: 56px;
      color: $black;
      outline: none;
      display: block;
      font-size: 12px;
      text-align: left;
      appearance: none;
      font-weight: 600;
      padding-left: 27px;
      border-radius: 27px;
      background-color: $gray;
    }
  }
}
