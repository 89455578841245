@import "src/styles/lib";

.ShopLayout {
  width: 100%;
  margin-top: 50px;
  position: relative;
  margin-bottom: 67px;

  @include mobile {
    margin-top: 30px;
  }

  &__head {
    margin-bottom: 40px;
    h1 {
      margin-bottom: 0;
    }

    @include mobile {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
    }
  }

  &--no-products {
    @include desktop {
      .ShopFilterActions {
        margin-bottom: 0;
      }
    }
  }
}
