@import "src/styles/lib";

.ProductSingleShareButton {
  width: 47px;
  height: 47px;
  border-radius: 100%;
  background: $secondary;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  &:hover {
    fill: $primary;
  }
  &:focus {
    outline: none;
  }

  &__tooltip_wrapper {
    margin-top: 5px !important;
    .arrow {
      top: 1px !important;
      &:before {
        border-bottom-color: $secondary !important;
      }
    }
  }
  &__tooltip {
    width: 150px;
    padding: 10px 15px 10px 10px !important;
    font-family: $font-family-base !important;
    background-color: $secondary !important;
  }

  &__items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      &:last-child {
        margin-right: 0;
      }
      margin-right: 5px;

      button {
        width: 22px;
        height: 22px;
        background: transparent;
        svg {
          width: 100%;
          height: 100%;
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
}
