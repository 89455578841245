@import "./../../../styles/_lib.scss";

.PaymentMethod {

  &__title {
    display: block;
		font-size: 13px;
		text-align: left;
		font-weight: 700;
		line-height: 18px;
		padding-left: 0;
		margin-bottom: 10px;
		letter-spacing: 0.15em;
		color: darken(#eee, 20%);
		text-transform: capitalize;
		@include mobile {
			margin-bottom: 2px;
		}
  }

  &__item {
    width: 100%;
		border-bottom: 1px solid $gray;
		padding: 10px 0;

    label {
      width: 100%;
      display: flex;
      cursor: pointer;
			align-items: center;
			margin-bottom: 0;

      @include mobile {
        align-items: start;
        flex-direction: column;
      }

      span.name {
				display: flex;
				align-items: center;
        color: $black;
        font-size: 16px;
        line-height: 1.3em;
        margin-bottom: 0;

        &:before {
          content: "";
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: $white;
          margin-right: 10px;
          display: inline-block;
          vertical-align: middle;
          border: 1px $border-color solid;
        }
      }

      :checked + span.name {
        &:before {
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url("./../../../assets/icons/check.svg");
        }
      }
    }

    span.cc-icon {
      width: 137px;
      height: 27px;
      display: block;
      margin-left: auto;
      background-repeat: no-repeat;

      @include mobile {
        margin-top: 10px;
        margin-left: 35px;
      }
    }

    span.other_payment-icon {
      width: 48px;
      height: 23px;
      display: block;
      margin-left: auto;
      background-repeat: no-repeat;

      @include mobile {
        margin-top: 10px;
        margin-left: 35px;
      }
    }

    span.cod-icon {
      width: 46px;
      height: 46px;
      display: block;
      margin-left: auto;
      background-repeat: no-repeat;

      @include mobile {
        margin-top: 10px;
        margin-left: 35px;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
