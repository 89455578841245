@import "./../../../styles/_lib.scss";

.ProductPrices {
  color: $black;

  h4 {
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    @include mobile {
      font-size: 12px;
    }
  }

  &.sale {
    h4 {
      margin: 0;
      &.regular {
        text-decoration-line: line-through;
        font-weight: normal;
        margin-right: 8px;
        color: lighten($black, 50%);
      }

      &.sale {
        // color: red;
      }
    }
  }
}
