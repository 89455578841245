@import "src/styles/lib";

.BrandsSidebar {
  &__title {
    display: block;
    font-size: 14px;
    text-align: left;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 28px;
    color: lighten($black, 72.5%);
  }

  @include desktop {
    &__search {
      margin-bottom: 10px;
    }
  }

  &-mobile {
    display: flex;
    margin-bottom: 20px;

    h4 {
      display: none;
    }

    .Select,
    .SearchInput {
      flex: 1;
    }

    .BrandsSidebar__search {
      margin-right: 20px;
    }
  }
}
