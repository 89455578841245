@import "./../../../styles/_lib.scss";

.ShopAttributes {
  margin-bottom: 10px;
  label {
    cursor: pointer;
  }

  small {
    font-size: 11px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 5px;
  }

  &__title {
    display: block;
    font-size: 14px;
    text-align: left;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 7px;
    color: lighten($black, 72.5%);
  }

  &__color {
    //width: 40px;
    //height: 15px;
    //cursor: pointer;
    //border: 1px solid $border-color;
    width: 24px;
    height: 24px;
    cursor: pointer;
    border: 1px solid $border-color;
    position: relative;

    > div {
      width: 80%;
      height: 80%;
      margin-right: 0 !important;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
