@import "src/styles/lib";

.CheckoutConfirmation {
  margin-top: 95px;

  &__head {
    h1 {
      font-size: 32px;
      line-height: 35px;
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
      line-height: 17px;
      margin-top: 8px;
      margin-bottom: 0;
    }
  }

  &__order_details {
    min-height: 250px;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/gray-bg.png");
    background-position: center center;
    //background-color: $gray_light;

    padding: 40px 17px 20px 17px;
    ul li {
      margin-bottom: 10px;
      padding-bottom: 12px;
      border-bottom: 1px solid $border-color;
      span {
        font-size: 14px;
        line-height: 25px;
      }

      .label {
        font-weight: bold;
      }
    }
  }

  &__total {
    height: 70px;
    margin-top: 20px;
    border: 1px solid #000000;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    font-size: 32px;
  }
}
