@import "src/styles/lib";

.HomeBlog {
  margin-top: 100px;

  &__title {
    margin-bottom: 30px;
    text-align: center;
    span {
      display: inline;
      font-size: 20px;
      font-weight: 800;
      border-bottom: 2px solid $purple;
    }
  }

  &__list {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(3, 4fr);

    margin-bottom: 30px;

    &--empty {
      grid-template-columns: repeat(1, 4fr) !important;
    }

    @include mobile {
      grid-gap: 15px;
      grid-template-columns: repeat(2, 4fr);
    }
    @include tablet {
      grid-template-columns: repeat(2, 4fr);
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    text-align: center;

    button {
      width: 100%;
      max-width: 150px;
    }
  }
}
