@import "./../../../styles/_lib.scss";

.ProductSingleTabs {
  width: 100%;
  margin-top: 100px;
  &__tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 30px !important;
    @include mobile {
      overflow: auto;
    }
  }
  &__tab {
    margin: 0 15px;
    font-size: 20px;
    font-weight: 800;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    @include mobile {
      white-space: nowrap;
    }
  }
  &__tab--active {
    border-bottom: 2px solid $purple;
  }
}
