@import './../../../styles/_lib.scss';

.ShippingMethod {
	margin-bottom: 30px;
  &__title {
    display: block;
		font-size: 13px;
		text-align: left;
		font-weight: 700;
		line-height: 18px;
		padding-left: 0;
		margin-bottom: 10px;
		letter-spacing: 0.15em;
		color: darken(#eee, 20%);
		text-transform: capitalize;
		@include mobile {
			margin-bottom: 2px;
		}
  }

  &__item {
    width: 100%;
    border-bottom: 1px solid $gray;
		padding: 10px 0;

    label {
      width: 100%;
      display: flex;
      cursor: pointer;
      align-items: center;
			justify-content: space-between;
			margin-bottom: 0;

      @include mobile {
        align-items: start;
        flex-direction: column;
      }

      :checked + span.name {
        &:before {
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url('./../../../assets/icons/check.svg');
        }
      }
    }
    span.name {
			display: flex;
			align-items: center;
      font-size: 16px;
      line-height: 1.3em;
      margin-bottom: 0;
      color: $black;

      &:before {
        content: '';
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: $white;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        border: 1px $border-color solid;
      }
    }
    span.price {
			display: block;
      font-size: 16px;
      line-height: 1.3em;
      font-weight: bold;
      color: $black;
      margin-bottom: 0;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.is--invalid.invalid-feedback {
  display: block;
}
