@import "./src/styles/lib";

.DefaultButton {
  margin: 0 auto;
  min-width: 112px;
  &--full {
    min-width: 200px;
  }

  a {
    width: 100%;
    height: 47px;
    border: none;
    outline: none;
    display: block;
    color: $white !important;
    padding: 0 25px;
    font-size: 12px;
    font-weight: 500;
    line-height: 47px;
    text-align: center;
    border-radius: unset;
    text-decoration: none;
    letter-spacing: $button-spacing;
    background-color: $black;
    text-transform: uppercase;
  }

  @include mobile {
    &.full-mob {
      width: 100%;
      max-width: unset;
    }
  }
}
