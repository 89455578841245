@import "./src/styles/lib";

.Login {
  display: flex;
  &__wrapper {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;

    &-text {
      display: inline-block;
      margin: 0;
      border-bottom: 1px solid #000;
    }

    button {
      margin-top: 30px;
    }
  }

  &__links {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .form-control {
    padding: 0 15px !important;
    border-radius: 0 !important;
  }
}
