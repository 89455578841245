@import "./../../styles/_lib.scss";

.Verification {
  margin-top: 50px;

  .HeadingTitle {
    text-align: center;
  }

  &__description {
    max-width: 300px;
    margin: 0 auto 30px;
    text-align: center;

    p {
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: $black;

    }
  }

  .DefaultButton {
    text-align: center;

    a {
      display: inline-block;
      width: auto;
    }
  }
}
