@import "../../../styles/lib";

.UserCart {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    svg {
      fill: $secondary;
    }
  }

  &__count {
    font-size: 12px;
    font-weight: 600;
    line-height: em(21px, 14px);
    color: #fff;

    position: absolute;
    top: -9px;
    right: -9px;
    transform: translate(-50%, -50%);
    background: $secondary;
    border-radius: 100%;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile {
      top: 0;
      right: -20px;
    }
  }

  //&:hover {
  //  .UserCartDropdown {
  //    opacity: 1;
  //    visibility: visible;
  //    transform: translateX(-50%) translateY(0);
  //  }
  //}
}
