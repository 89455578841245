@import "../../../styles/lib";

.TopHeader {
  padding: 13px 0;
  background: $green;
  @include tablet {
    padding: 0;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .Logo {
    width: 150px;
    margin-right: 40px;
    @include mobile {
      width: 200px;
      margin-right: 0;
    }
  }
}
