@import "./src/styles/lib";

.Footer {
  width: 100%;
  background-color: #efefef;
  padding: 30px 0 0 0;

  &__items {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include mobile {
      flex-direction: column;
    }
    &__right {
      width: 65%;
      @include mobile {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
      }
    }
    &--text {
      width: 35%;
      @include mobile {
        width: 100%;
      }
    }
  }

  &__social-media {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    @include mobile {
      margin-top: 0;
    }
    li {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
      a {
        color: #474646;
      }
      a:hover {
        color: $pink;
        svg {
          stroke: $pink;
        }
      }
    }
  }

  &__menu {
    display: flex;
    justify-content: flex-end;
    @include mobile {
      flex-direction: column;
    }
    li {
      margin-left: 15px;

      &:first-child {
        margin-left: 0;
        @include mobile {
          margin-left: 15px;
        }
      }

      a {
        color: #2b2b2b;
        font-weight: 700;
        font-size: 16px;
        &:hover {
          color: $pink;
        }
      }
    }
  }

  &__copyright {
    background-color: #eaeaea;
    padding: 10px 0;
    margin-top: 25px;

    span {
      display: block;
      text-align: center;
      font-size: 14px;
      line-height: 35px;

      @include mobile {
        font-size: 12px;
      }
    }
  }
}
