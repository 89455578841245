@import "./../../styles/_lib.scss";

.EventsItem {
  grid-row: span 1;
  position: relative;
  grid-column: span 1;

  a {
    width: 100%;
    height: 100%;
    display: block;
  }

  &--big {
    grid-row: span 2;
    grid-column: span 2;

    &:first-child {
      grid-row: 1 / 3;
      grid-column: 2 / 4;
      @include mobile {
        grid-row: span 1;
        grid-column: span 1;
      }
    }

    @include mobile {
      grid-row: span 1;
      grid-column: span 1;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;

    &:before {
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgba($black, 0.3);
      transition: 400ms ease-in-out;
    }

    &:hover {
      &:before {
        background: rgba($black, 0.5);
      }
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      min-height: 210px;
    }
  }

  &__title {
    bottom: 0;
    z-index: 1;
    width: 100%;
    margin: 0;
    display: flex;
    color: #000;
    font-size: 14px;
    line-height: 18px;
    position: absolute;
    padding: 15px 10px;
    align-items: center;
    background: $purple;
    span {
      @include mobile {
        overflow: hidden;
        max-height: 37px;
      }
      @include tablet {
        overflow: hidden;
        max-height: 37px;
      }
    }
  }
}

.EventsItemLoader {
  width: 100%;
  height: 100%;
  @include mobile {
    width: 100%;
    height: auto;
  }
}
