@import "./../../styles/_lib.scss";

.ProductSingleSlider {
  width: 660px;
  overflow: hidden;
  position: relative;
  min-height: 100%;
  max-height: 580px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  &__gallery {
    width: calc(100% - 80px);
  }
  &__item {
    img {
      object-fit: contain;
      max-height: 500px;
      @include mobile {
        height: 480px;
        max-height: 480px;
        object-fit: cover;
      }
    }
  }
  &--nostock {
    width: calc(100% - 80px);
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    span {
      font-size: 16px;
      color: #fff;
      letter-spacing: 0.25em;
      text-transform: uppercase;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @include mobile {
    width: auto;
    max-height: 480px;
  }

  .slick-slide,
  .slick-track {
    :focus {
      outline: none !important;
    }

    outline: none !important;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  &__thumbs {
    position: static !important;
    width: 70px;
    z-index: 3;

    .slick-list {
      height: 320px;
      overflow: hidden;
    }
    .slick-track {
      float: left;
    }

    &__item {
      border: 1px solid $gray;
      margin-right: 10px !important;
      width: auto !important;
      width: 70px !important;
      height: 70px !important;
      position: relative;
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba($color: #000000, $alpha: 0.65);
        transition: all ease-in-out 0.3s;
        opacity: 0;
      }
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .slick-current {
      .ProductSingleSlider__thumbs__item {
        &:before {
          opacity: 0.9;
        }
      }
    }
  }
}

.ProductSingleSliderLoader {
  @include tablet {
    width: 100%;
    height: auto;
    svg {
      width: 100%;
      height: auto;
      display: Block;
    }
  }
  @include mobile {
    width: auto;
    margin-left: -15px;
    margin-right: -15px;
  }
}
