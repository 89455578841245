@import "./../../../styles/_lib.scss";

.TransportInformation {
  display: flex;
  margin-top: 3px;
  align-items: center;
  justify-content: flex-start;
  @include mobile {
    margin-top: 7px;
  }
  &--right {
    justify-content: flex-end;
  }
  &:before {
    content: "";
    width: 20px;
    height: 30px;
    margin-right: 4px;
    display: inline-block;
    background: url("../../../assets/icons/truck.svg") no-repeat center;
    @include mobile {
      width: 20px;
      margin-right: 10px;
    }
  }
  p {
    flex: 1;
    margin-bottom: 0;
    color: #b9b9b9;
  }
  span {
    color: #018849;
  }
}
