@import "../../../styles/lib";

.SearchInput {
  position: relative;
  width: 100%;
  display: block;
  border-radius: 20px;
  background-size: 16px;
  background-color: $white;
  border: 1px $black solid;
  background-repeat: no-repeat;
  background-position: right 10px center;
  overflow: hidden;

  &__form_control {
    outline: none;
    border: none;
    width: 100%;
    background: transparent;
    font-weight: 600;
    font-size: 14px;
    padding: 6px 30px 5px 15px;

    &:focus {
      outline: none;
    }

    @include backgroundAutofill();
    @include placeholder {
      font-weight: 600;
      font-size: 14px;
      color: transparent;
    }
  }

  &__clear {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background-color: $white;

    &:hover {
      svg {
        fill: #2b2b2b;
      }
    }

    @include mobile {
      right: 18px;
      background-color: $gray;
    }
  }

  @include mobile {
    border-radius: 28px;
    background-color: $gray;
    background-position: right 20px center;
    border-color: transparent;

    &__form_control {
      padding: 19px 40px 14px 27px;

      color: $black;
      font-size: 12px;

      @include backgroundAutofill($gray);
      @include placeholder {
        color: $black;
      }
    }
  }
}
