@import "./src/styles/lib";

.ProfileOrderItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px $border-color solid;
  background-color: rgba($primary, 0.1);
  @include mobile {
    flex-flow: wrap;
    padding: 15px;
    margin-bottom: 15px;
  }

  &__thumb {
    width: 100%;
    height: 290px;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include mobile {
      img {
        width: 100%;
        display: block;
        height: 100%;
      }
    }
  }

  &__content {
    display: flex;
    width: 100%;

    @include mobile {
      flex-flow: column;
      margin: 15px;
    }

    @include tablet {
      flex-flow: column;
    }

    .DefaultButton {
      margin: unset;
      margin-left: auto;
      align-self: flex-end;
      a {
        background-color: $primary;
        color: $black;
        &:hover {
          background-color: $secondary;
        }
      }
      @include mobile {
        display: block;
        width: 100%;
        a {
          padding: 0 15px;
        }
      }
      @include tablet {
        margin-right: auto;
        margin-left: unset;
      }

      &:hover {
        a {
          opacity: 0.85;
        }
      }
    }
  }

  &__info {
    width: 100%;
    @include mobile {
      margin: 0 5px 10px;
      min-width: unset;
    }
    @include tablet {
      margin: 0 0 10px;
      min-width: unset;
    }

    &__item {
      color: $black;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 25px;
      @include mobile {
        display: block;
        width: 100%;
        strong {
          display: block;
          width: 100%;
        }
      }
    }
  }
}

.ProfileOrdersSingle {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-flow: wrap;
  position: relative;
  border: 1px $border-color solid;

  &__order-nr {
    width: 100%;
    align-self: flex-start;
    font-size: 14px;
    line-height: 25px;
    color: $black;
    margin-bottom: 10px;
  }

  &__left-side {
    width: 100%;
    @include mobile {
      width: 100%;
    }
    @include tablet {
      width: 100%;
    }
  }

  &__item {
    width: 100%;
    display: flex;
    margin-bottom: 20px;

    @include mobile {
      flex-flow: wrap;
      margin-right: 0;
    }
    @include tablet {
      margin-right: 0;
    }
  }

  .brand {
    display: block;
    padding: 0 6px;
    font-size: 12px;
    font-weight: 300;
    line-height: 21px;
    color: $black;
    border: 1px $border-color solid;
    margin-right: auto;
  }

  &__thumb {
    img {
      width: 170px;
      height: 170px;
      object-fit: cover;
    }

    @include mobile {
      width: 100%;
      img {
        width: 100%;
        display: block;
        height: 100%;
      }
    }
  }

  &__content {
    width: 100%;
    display: flex;
    margin-left: 15px;
    flex-flow: column;
    @include mobile {
      margin: 15px;
      width: auto;
    }
  }

  &__right-side {
    width: 100%;
    height: auto;
    background: $gray-light;
    padding: 20px;

    @include mobile {
      width: 100%;
      position: unset;
      margin: 0;
    }

    @include tablet {
      width: 100%;
      position: unset;
      margin: 20px 0 0;
    }

    &__contact-item {
      display: block;
      color: $black;
      display: block;
      font-size: 14px;
      line-height: 25px;

      &.right {
        margin-top: 30px;
        float: right;
      }
    }
  }

  &__address {
    margin-bottom: 30px;

    h4 {
      color: $black;
      font-size: 14px;
      line-height: 25px;
      text-transform: uppercase;
      font-weight: 700;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
