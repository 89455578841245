@import "../../../styles/lib";

.Search {
  position: relative;
  z-index: 101;

  @include tablet {
    display: none;
  }

  .input-group {
    width: 100%;
  }
  .form-control {
    height: 40px;
    padding: 0 20px;
    background: $gray_light;
    border: 1px solid lighten($black, 85%) !important;
    border-right: 0 !important;
    outline: none !important;
    box-shadow: none !important;

    &::placeholder {
      color: $black;
    }

    &:focus {
      box-shadow: none !important;
      background-color: $gray_light !important;
    }
  }

  .input-group-append {
    margin-left: 0;
  }

  &__btn_submit {
    width: 48px;
    height: 40px;
    border: 1px solid #d9d9d9 !important;
    box-shadow: none;
    background: $primary;
    &:focus {
      box-shadow: none !important;
    }
  }

  &__spinner {
    position: absolute;
    z-index: 10;
    right: 57px;
    top: 8px;
  }

  &__results {
    position: absolute;
    width: 100%;
    min-height: 50px;
    overflow: auto;
    top: 40px;
    z-index: 100;

    background: $gray_light;
    padding: 15px 20px 10px 20px;
    box-shadow: 1px 10px 14px 2px rgba(0, 0, 0, 0.07);
  }

  ul li:last-child {
    &__results__item {
      border-bottom: none;
    }
  }

  &__results__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px $border-color solid;
    margin-bottom: 5px;
    padding-bottom: 5px;

    &__thumb {
      width: 40px;
      height: 40px;
      border: 1px solid lighten($black, 75%);
      min-width: 40px;
      background: #fff;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__name {
      padding-left: 10px;
      font-size: 14px;
      font-weight: 500;
      color: lighten($black, 45%);
    }

    &:hover &__name {
      color: $black;
    }
    &:hover &__thumb {
      border-color: $black;
    }
    .ProductPrices h4 {
      margin-bottom: 0;
    }
  }

  &__results__more {
    margin-top: 10px;
    a {
      font-size: 12px;
      font-weight: 600;
      transition: none !important;
      color: lighten($black, 45%) !important;
    }

    a:hover {
      color: $black !important;
    }
  }

  &--has-results {
    .form-control {
      border-bottom-left-radius: 0 !important;
    }
    .btn {
      border-bottom-right-radius: 0 !important;
    }
  }

  &--mobile {
    z-index: 10;
    padding: 30px 10px;
    background-color: $gray;

    .Search__results {
      width: calc(100% - 20px);
      top: 70px;
    }
  }
}

@include mobile {
  .Search {
    &--mobile {
      left: 0;
      top: 100%;
      width: 100vw;
      position: absolute;
    }
  }
}
