@import "src/styles/lib";

.Menu {
  z-index: 100;
  position: relative;
  border-bottom: 1px solid $primary;
  background-color: #fff;

  @include tablet {
    display: none;
  }

  a {
    transition: none !important;
  }

  &-items {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  &__item {
    font-size: 18px;
    margin: 0 20px;
    cursor: pointer;
    text-transform: uppercase;
    padding: 15px 0;

    &--sale{
      position: relative;
      z-index: 1;
      a{
        color: #fff!important;
      }
      &:before {
        content: "";
        width: calc(100% + 30px);
        height: 60%;
        background: $pink;
        position: absolute;
        top: 50%;
        left: -15px;
        transform: translateY(-54%);
        z-index: -1;
      }
    }

    &_inner {
      padding: 15px 0;
    }
  }

  &__item--active {
    border-bottom: 2px solid $primary;
  }

  &-dropdown {
    padding: 20px 0 5px;
    position: absolute;
    width: 100%;
    left: 0;
    top: 58px;
    background: #ffffff;
    border-bottom: 1px solid $primary;

    &__list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 30px;
    }

    &__list-item {
      display: flex;
      flex-direction: column;
    }

    &__list-item-title {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 16px;
      color: $primary !important;
    }

    a:hover {
      color: $primary;
    }
  }
}
